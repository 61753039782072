/* Input Feilds */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
button {
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover {
    cursor: pointer;
}
input {
    outline: none;
    border: none;
}
textarea {
    outline: none;
    border: none;
    resize: none;
}
textarea:focus,
input:focus {
    border-color: transparent !important;
    background-color: transparent;
}
input::-webkit-input-placeholder {
    color: #555555;
    background-color: transparent;
}
input:-moz-placeholder {
    color: #555555;
    background-color: transparent;
}
input:-ms-input-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea::-webkit-input-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea:-moz-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea:-ms-input-placeholder {
    color: #555555;
    background-color: transparent;
}
/* .focus-input100 i {
    color: var(--secondaryColor2);
    font-size: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 20px);
    bottom: 0;
    left: 0;
    padding-left: 13px;
    padding-top: 2px;
    display: none;
}
.field {
    position: relative;
    font-size: 1rem;
    border-radius: 0;
    margin-bottom: 5%;
    border-radius: 0;
    width: 100%;
}
.field__input {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem 0.3rem 1rem;
    border-bottom: 1px solid #ccc;
    color: #fff;
    border-radius: 0;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
}
@media screen and (max-width: 450px) {
    .field__input {
        border-radius: 0;
    }
}
.field__input:focus {
    outline: none;
    background-color: transparent;
    border-radius: 0;
    color: #fff;
}
.field__input::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.2s ease-out;
}
.field__input::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    border-radius: 0;
}
.field__input:focus::-webkit-input-placeholder {
    color: var(--secondaryColor2);
}
.field__input:focus::-moz-placeholder {
    color: var(--secondaryColor2);
}
.field__label_wrap {
    box-sizing: border-box;
    pointer-events: none;
    cursor: text;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.field__label_wrap::after {
    content: "";
    box-sizing: border-box;
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.field__input:focus ~ .field__label_wrap::after {
    opacity: 1;
    border-radius: 0;
}
.field__label {
    position: absolute;
    top: 50%;
    line-height: 1;
    font-size: inherit;
    transition: top 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15), opacity 0.2s ease-out, font-size 0.2s ease-out;
    will-change: bottom, opacity, font-size;
    color: #fff;
    border-radius: 0;
    font-size: 15px;
}
.field__input:focus ~ .field__label_wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label_wrap .field__label {
    font-size: 0.75rem;
    top: 0.25rem;
    color: #ffbc00;
    border-radius: 0;
}
.field_v2 .field__label_wrap {
    overflow: hidden;
    color: #fff;
    border-radius: 0;
}
.field_v2 .field__label_wrap::after {
    border-bottom: 1px solid var(--secondaryColor);
    transform: translate3d(-100%, 0, 0);
    will-change: transform, opacity;
    transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
    border-radius: 0;
}
.field_v2 .field__input:focus ~ .field__label_wrap::after {
    transform: translate3d(0, 0, 0);
    transition-delay: 0;
    border-radius: 0;
} */
.formtitle {
    font-size: 45px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.signinbtn_cont {
    width: 100%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.form_btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--white);
    outline: none;
    transition: 0.3s;
    display: inline-block;
    margin-top: 20px;
    color: #fff;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bolder;
    transition: 0.3s;
    font-size: 18px;
    border-radius: 0;
}

.form_btn:hover {
    color: var(--secondaryColor);
    border-bottom: 1px solid var(--secondaryColor);
}

.error_p {
    font-size: 20px;
    float: left;
    color: red;
    margin-bottom: 2%;
    width: 100%;
    text-align: center;
    color: red;
    font-size: 16px;
    width: 100%;
}

.forgot_password_hover:hover {
    color: #ffbc00;
    transition: 0.5s;
}
