@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Scope+One&display=swap');
:root {
    --primary-color: #4ea685;
    --secondary-color: #57b894;
    --black: #000000;
    --white: #ffffff;
    --gray: #efefef;
    --gray-2: #757575;
    --primaryColor: #000;
    --secondaryColor: #ffbc00;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #262626 inset; /* Change the color to your own background color */
    -webkit-text-fill-color: white;
}
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: white;
}
input::selection {
    background: #666666;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    /* width: 100%;
  height: 100%;
 
  background: red;
   background: #000; 
  color: #fff;
  top:0;
  left: 0;
  margin: 0;
  padding: 0;
  overflow:auto;
  overflow-x:hidden;
  
  background: url(https://spotearned.com/storage/spotwebsite/hero.gif) repeat-y 50% 50%;
  background-size: cover;
  font-family: 'Montserrat', sans-serif; */
}
.websitebodyclass {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    color: #fff;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow-Y: auto;
    overflow-x: hidden;

    background: url(https://spotearned.com/storage/spotwebsite/hero.gif) repeat-y 50% 50%;
    background-size: cover;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.websitebodyclass::-webkit-scrollbar {
    width: 7px;
}
.websitebodyclass::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
.websitebodyclass::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
}
.websitebodyclass::-webkit-scrollbar-thumb:hover {
    background: #4d4d4d;
}
.mainContent {
    margin-top: 100px;
}
@media screen and (max-width: 800px) {
    .mainContent {
        margin-top: 30px;
    }
}
@media screen and (max-width: 560px) {
    .mainContent {
        margin-top: 10px;
    }
}
.mainContentGallery {
    margin-top: 120px;
}
/* .mainContent::-webkit-scrollbar {
  width: 0;
}
.mainContent::-webkit-scrollbar-track {
  box-shadow: transparent
}
.mainContent::-webkit-scrollbar-thumb {
  background: transparent; 
} */

/******** General *******/
a {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    text-decoration: none;
}
a:focus {
    outline: none !important;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul,
li {
    margin: 0px;
    list-style-type: none;
}
.cursor_none {
    cursor: none;
}
.txt-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.paginationlist {
    width: 100%;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    transition: 1s;
}
.paginationlist:hover {
    overflow-x: auto;
}
.paginationlist::-webkit-scrollbar {
    height: 5px;
    width: 100px;
}
.paginationlist::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
.paginationlist::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}
.paginationlist::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}
.pagination {
    padding-left: 0;
    list-style: none;
}
.page-link {
    position: relative;
    display: block;
    color: #fff;
    background-color: #404040;
    min-width: 5vh;
    height: 5vh;
    width: fit-content;
    padding: 0 1%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
    float: none;
    display: inline-block;
    transition: 0.3s;
}
.page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #ffbc00;
}
.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.page-link p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
/*** Buttons ***/
.btn-1 {
    background: transparent;
    letter-spacing: 1px;
}
.btn-1 svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.btn-1 rect {
    fill: none;
    stroke: #000;
    stroke-width: 0;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
}
.btn-1:hover {
    background: rgba(225, 51, 45, 0);
    letter-spacing: 1px;
}
.btn-1:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.btn_2 {
    background: transparent;
    color: #fff;
    background: #fff;
    color: var(--primaryColor);
}
.btn_2 svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.btn_2 rect {
    fill: none;
    stroke: #ffbc00;
    stroke-width: 0;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
}
.btn_2:hover {
    background: rgba(225, 51, 45, 0);
    letter-spacing: 1px;
}
.btn_2:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn_2:hover span {
    color: #ffbc00;
}

.btn_white {
    background: transparent;
    color: #fff;
}
.btn_white svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.btn_white rect {
    fill: none;
    stroke: #fff;
    stroke-width: 0;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
}
.btn_white:hover {
    background: rgba(225, 51, 45, 0);
    letter-spacing: 1px;
}
.btn_white:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.btnn {
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 45px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    letter-spacing: 1px;
    background-color: transparent;
}
.cardbtn {
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    line-height: 35px;
    position: relative;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 5px;
    letter-spacing: 1px;
    background-color: transparent;
}
.cardbtn.delete {
    background-color: #d92550;
}
.cardbtn.delete:hover {
    background-color: #ae1e3f;
}
.btnn button {
    color: #fff;
}
.btnn:hover {
    text-decoration: none;
    color: #ffbc00;
}
.btnn:hover button {
    text-decoration: none;
    color: #ffbc00;
}
.btnn span {
    color: #fff;
}

.pointer {
    cursor: pointer;
}
/*** Section Title ***/
.section_title_two_lines {
    text-align: center;
    padding-bottom: 20px;
}

.section_title_two_lines h2 {
    font-size: 32px;
    text-transform: uppercase;
    position: relative;
    color: #fff;
}

.section_title_two_lines h2::before,
.section_title_two_lines h2::after {
    content: '';
    width: 60px;
    height: 1px;
    background: #fff;
    display: inline-block;
}

.section_title_two_lines h2::before {
    margin: 0 15px 10px 0;
}

.section_title_two_lines h2::after {
    margin: 0 0 10px 15px;
}

.section_title_two_lines2 {
    text-align: center;
}

.section_title_two_lines2 h2 {
    font-size: 25px;
    text-transform: uppercase;
    position: relative;
    color: #fff;
}

.section_title_two_lines2 h2::before,
.section_title_two_lines2 h2::after {
    content: '';
    width: 60px;
    height: 1px;
    background: #fff;
    display: inline-block;
}

.section_title_two_lines2 h2::before {
    margin: 0 15px 10px 0;
}

.section_title_two_lines2 h2::after {
    margin: 0 0 10px 15px;
}

.section_title_two_lines_resp {
    text-align: center;
    padding-bottom: 10px;
    position: relative;
}

.section_title_two_lines_resp h2 {
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 20px;
    position: relative;
    color: #fff;
    overflow-wrap: break-word;
}

.section_title_two_lines_resp h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #fff;
    bottom: 1px;
    left: calc(50% - 60px);
    text-transform: capitalize;
}

/*** Cursor ***/
.cursor,
.cursor2,
.cursor3 {
    position: fixed;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    left: -100px;
    top: 50%;
    mix-blend-mode: normal;
    z-index: 1000;
}
.cursor {
    background-color: #fff;
    height: 0;
    width: 0;
    z-index: 99999;
}
.cursor2,
.cursor3 {
    height: 36px;
    width: 36px;
    z-index: 99998;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.cursor2.hover,
.cursor3.hover {
    -webkit-transform: scale(2) translateX(-25%) translateY(-25%);
    transform: scale(2) translateX(-25%) translateY(-25%);
    border: none;
}
.cursor2 {
    border: 2px solid #fff;
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}
.cursor2.hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
}

/*** Page Transition ***/
.loading-screen {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-color: #4d4d4d;
    width: 0%;
    height: 100%;
}

.load-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;
}
#mainnn {
    margin: 0;
    padding: 0;
    height: auto;
}

@media screen and (max-width: 325px) {
    .loading-page .counter h1 {
        font-size: 200px;
    }
}

.rev-block span {
    opacity: 0;
    animation: appear-text 0.0001s linear forwards;
    animation-delay: 1.4s;
}
.rev-block:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: #404040;
    animation: rev-block 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation-delay: 1s;
}
@keyframes rev-block {
    0% {
        left: 0;
        width: 0%;
    }
    50% {
        left: 0;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 0%;
    }
}
@keyframes appear-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.rev-block2 span {
    animation-delay: 2s;
}
.rev-block2.rev-block:after {
    background: #404040;
    animation-delay: 1.4s;
}

.pagination-text {
    transition: 0.3s;
}
.pagination-text:hover {
    color: #ffbc00;
}

.new_card {
    position: relative;
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background: #fff;
    margin-bottom: 30px;
    transition: 0.3s;
    color: #728394;
}

.check_container {
    margin: 0 auto;
    padding: 1em;
    border-radius: 0.5em;
    color: #000;
}
.check_container h1 {
    margin: 0;
    text-align: center;
}
/*** Skeleton Preloader *****/
.skeleton_box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #1a1a1a;
    border-radius: 10px;
}
.skeleton_box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.3));
    -webkit-animation: shimmer 2s infinite;
    animation: shimmer 2s infinite;
    content: '';
}
@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.pinned-user-username a {
    color: white;
    font-size: 15px;
    transition: 0.3s;
}
.pinned-user-username a:hover {
    text-decoration: none;
    color: #ffbc00;
}
/****** Pinned User Card ******/
.pinned-user-card {
    color: #fff;
    position: relative;
    overflow: hidden;
    /*width: 100%;*/
    background: #000000;
    text-align: left;
    height: 250px;
    width: 250px;
    border-radius: 50%;
}
.pinned-user-card * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.pinned-user-card img {
    max-width: 100%;
    opacity: 1;
    width: 100%;
    margin: auto;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}
.pinned-user-card .pinned-user-info {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    margin: auto;
    padding: 30px 3em;
    width: 100%;
    height: 50%;
}
.pinned-user-card .pinned-user-info::before {
    position: absolute;
    top: 10px;
    right: 30px;
    bottom: 10px;
    left: 100%;
    border-left: 4px solid rgba(255, 255, 255, 0.8);
    content: '';
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}
.pinned-user-card .pinned-user-username,
.pinned-user-card p {
    margin: 0 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s, -o-transform 0.35s, transform 0.35s;
}
.pinned-user-card .pinned-user-username {
    color: white;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    -webkit-transform: translate3d(30%, 0%, 0);
    transform: translate3d(30%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}
.pinned-user-card p {
    font-weight: 200;
    -webkit-transform: translate3d(0%, 30%, 0);
    transform: translate3d(0%, 30%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.pinned-user-card:hover img {
    opacity: 0.3;
}
.pinned-user-card:hover .pinned-user-info .pinned-user-username {
    opacity: 1;
    text-decoration: none;
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}
.pinned-user-card .pinned-user-info .pinned-user-username:hover {
    color: #ffbc00;
    text-decoration: none;
}
.pinned-user-card:hover .pinned-user-info p {
    opacity: 0.9;
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}
.pinned-user-card:hover .pinned-user-info::before {
    background: rgba(255, 255, 255, 0);
    left: 30px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

/* Loader */
.loader_1 {
    width: 48px;
    height: 48px;
    border: 5px solid #f2f2f2;
    border-bottom-color: #eac435;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
}
@-webkit-keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.card {
    position: relative;
    padding: 20px 12px;
    background: #fff;
    transition: 0.3s;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
}
.showApplicantCard {
    position: relative;
    padding: 10px;
    background: #fff;
    color: white;
    transition: 0.3s;
    border-radius: 20px;
    background: #262626;
}
.showApplicantCard .primary_link_hover {
    color: white !important;
}
.showApplicantCard img {
    box-shadow: none !important;
}
.show_applicant_card_active {
    background: #262626 !important;
}
.section_title {
    margin: 0;
    padding: 0;
}
.section_title p {
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.section_title p:after {
    content: '';
    width: 100px;
    height: 1px;
    background-color: var(--secondaryColor);
    position: absolute;
    top: 65%;
    opacity: 1;
    margin-left: 0.5%;
}
@media screen and (max-width: 600px) {
    .section_title p:after {
        content: '';
        width: 50px;
        height: 1px;
        background-color: var(--secondaryColor);
        position: absolute;
        top: 65%;
        opacity: 1;
        margin-left: 1%;
    }
}

.general_icon {
    box-shadow: none !important;
}
