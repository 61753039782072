.button{
    padding: 10px;
    background-color: black;
    color: white;
    border-radius: 10px;
    margin-right: 10px;
    transition: 0.4s;
}
.button:hover{
    background-color: #ffbc00;
    color:white;
}
.button1{
    color: black;
    margin-right: 10px;
    transition: 0.4s;
    text-decoration: underline;
    font-size: 15px;
    height: 30px;
}
.button1:hover{
    color: #ffbc00;
}
.icon_cont {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 500px) {
    .icon_cont {
        width: 3vh;
        height: 3vh;
    }
}
.text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header{
    position: relative !important;
}
.header::before{
    position: absolute;
    content: '';
    top: 32px;
    left: 0;
    background-color: #ccc;
    transition: 0.3s;
    height: 1.5px;
    width: 100%;
    transition: 0.4s !important;  
}
.header::after{
    position: absolute;
    content: '';
    top: 28px;
    left: 0;
    background-color: #ffbc00;
    transition: 0.3s;
    width: 100px;
    height: 4px;
    transition: 0.4s !important;  
}
.headernotactive::after{
    display: none !important;
}
.headernotactive .headeractive::after{
    position: absolute;
    content: '';
    top: 28px;
    left: 0;
    background-color: #ffbc00;
    transition: 0.3s;
    width: 100px;
    height: 4px;
    transition: 0.4s !important;  
}
.maincont{
    overflow: hidden;
    cursor: pointer;
}
.cardcontainer{
    height: 60px;
    background-color: transparent;
    opacity: 1;
    transition: 0.6s;
    position: absolute;
    bottom: 0;
    width: 94%;
    padding: 10px;
    color: white;
    padding-left: 20px;
}
.maincont:hover .cardcontainer{
    height: 80px;
    color: #ffbc00;
}
.scrollcont{
    overflow: auto !important;
    background-color: white;
    height: 100vh !important;
    padding-bottom: 100px;
}
.scrollhorizontalcard{
    width: max-content !important;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: transparent !important;
    position: relative;
}
.scrollhorizontalcard p{
    flex:1;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    color: #000;
    padding-left: 10px;
    background-color: transparent;
    border-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.scrollhorizontalcardactive::after{
    position: absolute;
    content: '';
    top: 26px;
    background-color: #ffbc00;
    transition: 0.3s;
    height: 5px !important;
    transition: 0.4s !important;
    width: 100%;
    left: 0 !important
}
/*  */
.tabs {
    position: relative;
    height: auto;
    display: flex;
    width: 100% !important;
    flex-direction: column;
    word-wrap: break-word;
}
.tabs input[name='tab-control'] {
    display: none;
}
.tabs .tabsul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    flex-direction: row !important;
}
.tabs .tabsul .tabsli {
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    position: relative !important;
    flex: 1
}
.tabs .tabsul::before{
    position: absolute !important;
    content: '' !important;
    top: 32px !important;
    left: 0 !important;
    background-color: #ccc !important;
    height: 1.5px !important;
    width: 95% !important;
    transition: 0.4s !important;  
}
.tabs .tabsul .tabsli label {
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: start;
}
.tabs .tabsul .tabsli label:hover {
    outline: 0;
 }
.tabs .tabsul .tabsliactive::after{
    position: absolute;
    content: '';
    top: 28px;
    left: 0;
    background-color: #ffbc00;
    transition: 0.3s;
    width: 100px;
    height: 4px;
    transition: 0.4s !important; 
} 
.tabsliactive:nth-child(2)::after{
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
}
.tabsliactive:nth-child(3)::after{
    left: 55px !important;
}
@media screen and (max-width: 800px) {
    .tabs .tabsul {
        width: 100%;
        display: block;
        white-space: nowrap;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .tabs .tabsul .tabsli {
        width: fit-content !important;
        float: none;
        display: inline-block;
        zoom: 1;
    }
}
@media (max-width: 576px) {
    .tabs .tabsul .tabsli {
        width: fit-content !important;
    }
    .tabs .tabsul::before{
        bottom: 0 !important; 
    }
    .tabs .tabsul::before{
        width: 110% !important;
    }
}
.socialmediaiconcontainer{
    width: 5.5vh;
    height: 5.5vh;
    border-radius: 100%;
    border:2px solid white;
    color: white !important
}
.socialmediaiconcontainer:hover{
    transition: .3s !important;
    border-color: var(--secondaryColor) !important;
    color: var(--secondaryColor) !important;
    cursor: pointer !important;
}
@media (max-width: 700px) {
    .socialmediaiconcontainer{
        width: 5vh;
        height: 5vh;
    }
}

.profileimage{
    width: 190px; 
    height: 190px; 
    position: absolute; 
    top: -110px;
    border-radius: 100%;
    border: 4px solid white
}
@media screen and (min-width:1020px) and (max-width:1025px){
    .profileimage{
        width: 75% !important;
        height: 130px; 
        top:-70px
    }
}