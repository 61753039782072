.profile_pic_container {
    margin: 0 10px 0 0;
    float: left;
}
.profile_pic {
    max-width: 400px;
    max-height: 400px;
    border-radius: 10px;
    float: left;
    shape-outside: square();
    background-clip: content-box;
}
.profile_pic_resp {
    border-radius: 10px;
}
.scrollcont{
   overflow: auto !important;
   height: 100% !important;
   padding-bottom: 100px;
  }