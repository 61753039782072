/*** Light Gallery ***/
.demo_gallery > .lightgalleryclass {
    margin-bottom: 0;
    cursor: pointer;
}
.demo_gallery > .lightgalleryclass .liclassgallery {
    margin-bottom: 15px;
    display: inline-block;
}
.demo_gallery > .lightgalleryclass .liclassgallery a {
    border: 3px solid #ccc;
    border-radius: 15px;
    display: block;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 200px;
}
.demo_gallery > .lightgalleryclass .liclassgallery a > img {
    border-radius: 15px;
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    /* max-height: 50%; */
    width: 100%;
    margin: auto;
    object-fit: contain;
    height: 100%;
}
.demo_gallery > .lightgalleryclass .liclassgallery a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}
.demo_gallery > .lightgalleryclass .liclassgallery a:hover .demo_gallery_poster > img {
    opacity: 1;
}
.demo_gallery > .lightgalleryclass .liclassgallery a:hover .demo_gallery_poster > i {
    opacity: 1;
}
.demo_gallery > .lightgalleryclass .liclassgallery a .demo_gallery_poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}
.demo_gallery > .lightgalleryclass .liclassgallery a .demo_gallery_poster > img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}
.demo_gallery > .lightgalleryclass .liclassgallery a .demo_gallery_poster > .demo-poster-hover-icon-container {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.demo_gallery .lightgalleryclass .liclassgallery a:hover .demo-poster-hover-icon-container {
    opacity: 1;
}
.demo_gallery .lightgalleryclass .liclassgallery a:hover .demo_gallery_poster {
    background-color: rgba(0, 0, 0, 0.5);
}
.demo_gallery .video .demo_gallery_poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
}
.demo_gallery_poster-icon-container {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    color: var(--secondaryColor);
    background-color: white;
    align-items: center;
    justify-content: center;
}
/* .demo_gallery  .lightgalleryclass .liclassgallery a .demo_gallery_poster > i {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  padding:1%;
  color:var(--secondaryColor);
} */
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: #ffbc00;
}
.lg-progress-bar .lg-progress {
    background-color: #ffbc00;
    height: 5px;
    width: 0;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: pointer;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: pointer;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
}
#lg-counter {
    color: #ffbc00;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle;
}
.demo_gallery_poster_icon_container {
    background-color: #ffbc00;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    color: white;
    align-items: center;
    justify-content: center;
}
