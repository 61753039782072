.tabshorizontal .tabsul{
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex:1;
    background-color: transparent;
    
}
.tabshorizontal .tabsli{
  width: max-content !important;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: transparent !important;
    position: relative;
}
.tabshorizontal .tabsul .tabsli label {
    flex:1;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    color: #000;
    padding-left: 10px;
    background-color: transparent;
    border-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
   
}
.tabshorizontal .tabsul .tabsli label:hover {
  color: #ffbc00 !important;
}
.tabshorizontal .tabsul .tabsliactive::after{
  position: absolute;
  content: '';
  top: 28px;
  background-color: #ffbc00;
  transition: 0.3s;
  height: 4px;
  transition: 0.4s !important;
  width: 100%;
  left:5px !important
}
::-webkit-scrollbar {
  display: none;
}
