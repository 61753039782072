@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Scope+One&display=swap');
*,
::after,
::before {
    box-sizing: border-box;
}
:root {
    --red: #fc0006;
    --danger: #d92550;
    --dangerhover: #ae1e3f;
    --darkgrey: #595959;
    --secondaryColor: #ffbc00;
    --secondaryColor2: #728394;
    --primaryColor: #000;
    --success: #009973;
    --lightestgrey: #f0f0f0;
    --darkestgrey: #404040;
    --light: #b8c3d5;
}
.dashboardbodyclass {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background: #eff0f6;
    height: 100vh;
}
body {
    background: #eff0f6;
}
body::-webkit-scrollbar {
    width: 7px;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}
body::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 5px;
}
body::-webkit-scrollbar-thumb:hover {
    background-color: #595959;
}
.hover_card {
    transition: 0.3s;
}
.hover_card:hover {
    transform: translateY(-5px);
}
.hover_card_active {
    transform: translateY(-5px);
}

/******* General ********/
.icon_cont {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 500px) {
    .icon_cont {
        width: 3vh;
        height: 3vh;
    }
}

.breakword {
    overflow-wrap: break-word !important;
}
a:hover {
    text-decoration: none;
}
link:hover {
    text-decoration: none;
}
ul li {
    list-style-type: none;
}
ul link {
    list-style-type: none;
}
.not_found_text {
    font-size: 25px;
}
.not_found_text i {
    font-size: 100px;
    display: block;
}
/* Sub Scrollbar */
.sub_scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;
}
.sub_scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    cursor: pointer;
}
.sub_scrollbar::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 5px;
    cursor: pointer;
}
.sub_scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
    cursor: pointer;
}

/* Text styles */
.text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.primary_link_hover_container:hover .primary_link_hover {
    color: var(--secondaryColor);
}
.primary_link_hover {
    cursor: pointer;
    color: #000;
    transition: 0.3s;
}
.primary_link_hover:hover {
    color: var(--secondaryColor);
}
/* Filter fab */
.filter_fab {
    position: absolute;
    top: -9%;
    right: 2%;
}
.filter_fab i {
    font-size: 13px;
}
.filter_fab .fab_tooltiptext {
    opacity: 0;
    width: 80px;
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
    text-align: center;
    border-radius: 8px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -40px;
    color: white;
    font-size: 13px;
    transition: 0.3s;
}
.filter_fab .fab_tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primaryColor) transparent;
}
.filter_fab:hover .fab_tooltiptext {
    opacity: 1;
    top: 120%;
}
@media screen and (max-width: 800px) {
    .filter_fab {
        right: 3%;
    }
    .fab_tooltiptext {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .filter_fab {
        right: 5%;
    }
}
.danger_fab {
    color: var(--danger) !important;
}
.danger_fab:hover {
    color: var(--dangerhover) !important;
}
.danger_fab .fab_tooltiptext {
    background-color: var(--danger);
}
.danger_fab .fab_tooltiptext::after {
    border-color: transparent transparent var(--danger) transparent;
}
/* Cards */
.card_container_whitesmoke {
    padding: 10px 15px;
    background-color: #fdfcfa;
    border-radius: 20px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.card {
    position: relative;
    box-shadow: 0 0 10px #e6e6e6;
    /* box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15)!important; */
    padding: 7px 12px;
    background: #fff;
    transition: 0.3s;
    color: #000;
    border-radius: 20px;
    background-color: #fff;
    border: 2px solid #e6e6e6;
}
.card_border15 {
    border-radius: 15px;
}
/* Icons */
.general_icon {
    background: white;
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    box-shadow: 0 0 10px #ccc;
    transition: 0.3s;
    /* box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.4);
  -webkit-box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.4);
  -moz-box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.4); */
    cursor: pointer;
}
.general_icon:hover {
    color: #ffbc00;
}
.general_profile_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.general_profile_image_container img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 10px #ccc;
    border: 2.5px solid white;
}
.general_image_preview_container {
    width: 20%;
    height: 20%;
    position: relative;
    background: white;
    padding: 5px;
    box-shadow: 0 0 10px #ccc;
    border-radius: 10px;
    margin: auto;
}
.general_image_preview_container img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.topbar_divider {
    width: 0;
    border-right: 2px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
}
/* Badges */
.badge {
    display: inline-block;
    font-size: 90%;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: center;
    width: 3vh;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.badge_counter {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    margin-top: -0.25rem;
    color: #fff;
    background: red;
}
@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}
.badge:empty {
    display: none;
}
/* Buttons */
.btnn {
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 35px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    letter-spacing: 1px;
    font-weight: 500;
}
.btn-transparent {
    background-color: transparent;
    border: none;
}
.btn1 {
    background: rgba(0, 0, 0, 0.8);
    letter-spacing: 1px;
}
.btn1 svg {
    height: 35px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.btn1 rect {
    fill: none;
    stroke: #000;
    stroke-width: 0;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
}
.btn1:hover {
    background: rgba(225, 51, 45, 0);
    letter-spacing: 1px;
    color: #ffbc00;
}
.btn1:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn2 {
    background: transparent;
    color: var(--primaryColor);
    transition: 0.3s;
}
.btn2 svg {
    height: 35px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.btn2 rect {
    fill: none;
    stroke: #000;
    stroke-width: 0;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
}
.btn2:hover {
    background: rgba(225, 51, 45, 0);
    letter-spacing: 1px;
    color: var(--secondaryColor);
}
.btn2:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
/* Bottom Tooltip */
.bottom_tooltip {
    position: relative;
}
.bottom_tooltip .tooltiptext {
    opacity: 0;
    width: 82px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    transition: 0.3s;
    top: 115%;
    left: 50%;
    margin-left: -40px;
}
.bottom_tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primaryColor) transparent;
}
.bottom_tooltip:hover .tooltiptext {
    opacity: 1;
    top: 125%;
}
.bottom_tooltip i {
    font-size: 14px;
}
/* Section title */
.section_title {
    margin: 0;
    padding: 0;
}
.section_title p {
    position: relative;
    color: #000;
    font-size: 18px !important;
    font-weight: 600;
}
.section_title p:after {
    content: '';
    width: 100px;
    height: 1px;
    background-color: var(--secondaryColor);
    position: absolute;
    top: 65%;
    opacity: 1;
    margin-left: 0.5%;
}
.section_title2 p:after {
    width: 60px !important;
}

@media screen and (max-width: 600px) {
    .section_title p:after {
        content: '';
        width: 50px;
        height: 1px;
        background-color: var(--secondaryColor);
        position: absolute;
        top: 65%;
        opacity: 1;
        margin-left: 1%;
    }
}

/* Skeleton Preloader */
.border_left_skeleton_box {
    border-left: 7px solid #dddbdd !important;
}

.skeleton_box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    border-radius: 10px;
}
.skeleton_box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 2s infinite;
    animation: shimmer 2s infinite;
    content: '';
}
@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
/* Loader */
.loader_1 {
    width: 48px;
    height: 48px;
    border: 5px solid #f2f2f2;
    border-bottom-color: #eac435;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
}
@-webkit-keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* Pagination */
.page_link {
    position: relative;
    display: block;
    color: #fff;
    background-color: #404040;
    min-width: 3vh;
    height: 3vh;
    width: fit-content;
    padding: 0 0.5%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
    float: none;
    display: inline-block;
    transition: 0.3s;
    font-size: 12px;
}
.page_link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #ffbc00;
}
.page_link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
/*** Plus Icon ***/
.addnewsfeedcircle {
    height: 35px;
    width: 35px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 10px #ccc;
    z-index: 9;
    transition: 0.3s;
    cursor: pointer;
}
.addnewsfeedcircle .plus_icon {
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: opacity 0.3s, transform 0.2s;
}
@media screen and (max-width: 500px) {
    .addnewsfeedcircle {
        height: 27px;
        width: 27px;
    }
    .addnewsfeedcircle .plus_icon {
        font-size: 15px;
    }
}
.addnewsfeedcircle:hover .plus_icon {
    transform: rotate(360deg);
}
.addnewsfeedcircle:hover .plus_icon {
    color: #ffbc00;
}

.back_button {
    background: white;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    box-shadow: 0 0 10px #ccc;
}
.back_button:before,
.back_button:after {
    content: '';
    width: 55%;
    height: 2px;
    background: #000;
    position: absolute;
    top: 48%;
    left: 22%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.back_button:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.back_button:hover:before {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 29%;
    left: 18%;
    background-color: #ffbc00;
}
.back_button:hover:after {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 67%;
    left: 18%;
    background-color: #ffbc00;
}

/*** General Avatar Upload ***/
.avatar_upload .avatar_edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}
.avatar_edit input {
    display: none;
}
.avatar_upload .avatar_edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.avatar_upload .avatar_edit input + label i {
    transition: 0.3s;
    color: var(--primaryColor);
}
.avatar_upload .avatar_preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.avatar_upload_logo .avatar_preview {
    border-radius: 10px;
}
.avatar_upload .avatar_preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.avatar_preview img {
    border-radius: 50%;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--primaryColor);
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondaryColor);
}
.imgpreviewtxt,
.imgpreviewpostertxt {
    position: absolute;
    top: 50%;
    margin-top: -10px;
}
.logo_upload_website .avatar_preview {
    border: 3px solid #e6e6e6;
}
.logo_upload .avatar_preview {
    border-radius: 20px;
}
.logo_upload .avatar_preview img {
    border-radius: 10%;
}
/*********** Page Inner ***********/
.app_main {
    flex: 1;
    display: inline;
    position: relative;
}
.app_main .app_main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
    padding-left: 230px;
}
.app_main__inner {
    padding: 15px 20px 20px 25px;
    flex: 1;
}
@media (max-width: 1025px) {
    .app_main__outer {
        padding-left: 190px !important;
    }
    .app_main__inner {
        padding: 12px 20px 20px 20px;
    }
}
@media screen and (max-width: 800px) {
    .app_main__outer {
        padding-left: 0 !important;
    }
    .app_main__inner {
        padding: 12px 20px 20px 20px;
    }
}

@media screen and (max-width: 500px) {
    .app_main__inner {
        padding: 12px 10px 20px 10px;
    }
}

/* Chosen Categories From Filter Card */
.chosensubcatcontainer {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    transition: 3s;
}
.chosensubcat_card {
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #e6e6e6;
    box-shadow: 0 0 10px #e6e6e6;
}
.chosensubcat_card_right_badge {
    width: 3vh;
    height: 3vh;
    background: var(--primaryColor);
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
}
.chosensubcat_card .tooltip {
    position: relative;
    display: inline-block;
}
.chosensubcat_card .tooltip .tooltiptext {
    opacity: 0;
    width: 90px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -2px;
    right: 115%;
    font-size: 13px;
    transition: 0.3s;
}
.chosensubcat_card .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #000;
}
.chosensubcat_card .tooltip:hover .tooltiptext {
    opacity: 1;
    right: 135%;
}
/* main common card */
.indproj_img {
    background-color: white;
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0 10px #ccc;
    /* margin-right: 5px; */
}
.proj_proposal_card .carddesc {
    overflow-wrap: break-word;
    padding: 0 90px;
    font-size: 15px;
    /* line-height: 16px; */
}
.details_section {
    padding: 0 90px;
}

.indproj_separator {
    margin-top: -3%;
    z-index: 1;
}
.details_card i {
    font-size: 17px;
    color: var(--secondaryColor2);
    opacity: 0.6;
    transition: 0.3s;
}
.details_card:hover i {
    opacity: 1;
}
@media screen and (max-width: 1025px) {
    .indproj_separator {
        margin-top: -4%;
    }
    .proj_proposal_card .carddesc {
        padding: 0 70px;
    }
}

.hidden-input {
    display: none;
}
.admin_card {
    border-radius: 10px;
}
/* 
 */
.apply_show_sample_word_card i {
    color: #cfd2e3 !important;
}
.apply_show_sample_word_card_active {
    background-color: #ffbc00 !important;
}
.apply_show_sample_word_card_active .primary_link_hover {
    color: #fff !important;
}
.apply_show_sample_word_card_active i {
    color: #fff !important;
}
/********* Set Up User Account *********/
#prevtrialstitle {
    display: block;
}
.stepscompleted {
    color: #ffbc00;
    font-size: 20px;
    margin: 2% 0;
}
.applyservice_box {
    /* box-shadow: 0 0 10px #ccc; */
    box-shadow: 0 0 10px #e6e6e6;
    height: 100%;
    padding: 20px 0px;
    text-align: center;
    border-radius: 20px;
    transition: 0.3s;
}

.applyservice_box:hover {
    transform: scale(1.05);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.applyservice_box .applyserviceboxicon {
    font-size: 25px;
    padding: 30px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 15px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
}

.applyservice_box h3 {
    color: #000;
    font-weight: 500;
    font-size: 25px;
}

.applyservice-boxodd {
    border-bottom: 3px solid #000;
}

.applyservice-boxodd .applyserviceboxicon {
    color: #000;
    background: #e6e6e6;
}

.applyservice_boxeven {
    border-bottom: 3px solid #ffbc00;
}

.applyservice_boxeven .applyserviceboxicon {
    color: #000;
    background: whitesmoke;
    border-radius: 20px;
    /* border-bottom: 3px solid #000; */
}
/* .applyservice_boxeven .fa-user {
    font-size: 100px;
} */
.label-cbx {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
}
.label-cbx input:checked + .checkbox {
    border-color: var(--secondaryColor);
}
.label-cbx input:checked + .checkbox svg path {
    fill: var(--secondaryColor);
}
.label-cbx input:checked + .checkbox svg polyline {
    stroke-dashoffset: 0;
}
.label-cbx:hover .checkbox svg path {
    stroke-dashoffset: 0;
}
.label-cbx .checkbox {
    position: relative;
    top: 2px;
    float: left;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 3px;
}
.label-cbx .checkbox svg {
    position: absolute;
    top: -2px;
    left: -2px;
}
.label-cbx .checkbox svg path {
    fill: none;
    stroke: var(--secondaryColor);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 71px;
    stroke-dashoffset: 71px;
    transition: all 0.6s ease;
}
.label-cbx .checkbox svg polyline {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 18px;
    stroke-dashoffset: 18px;
    transition: all 0.3s ease;
}
.label-cbx > span {
    pointer-events: none;
    vertical-align: middle;
}

.invisible {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;
}
.favactive {
    color: #009973;
}
