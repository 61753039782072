.app_header {
    height: 60px;
    /* display: flex; */
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
}
.header-username {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header_pagetitle {
    font-size: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0s;
    font-weight: 500;
}
.pageTitle.inactive {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
@media screen and (max-width: 500px) {
    .header_pagetitle {
        font-size: 20px !important;
    }
}
/* header Search Bar */
/* .header_search .focus_input100 i {
    color: var(--secondaryColor2);
    font-size: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 20px);
    bottom: 0;
    left: 0;
    padding-left: 13px;
    padding-top: 2px;
    display: none;
} */
.header_search_field {
    position: relative;
    font-size: 1rem;
    border-radius: 0;
    margin-bottom: 5%;
    border-radius: 0;
}
.header_search_field__input {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem 0.3rem 1.2rem;
    border-bottom: 1px solid #ccc;
    color: #000;
    border-radius: 0;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
}
.header_search_field__input:focus {
    outline: none;
    background-color: transparent;
    border-radius: 0;
    color: #000;
}
.header_search_field__input::-webkit-input-placeholder {
    color: var(--light);
    opacity: 1;
    transition: opacity 0.2s ease-out;
}
.header_search_field__input::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    border-radius: 0;
}
.header_search_field__input:focus::-webkit-input-placeholder {
    color: var(--secondaryColor2);
}
.header_search_field__input:focus::-moz-placeholder {
    color: var(--secondaryColor2);
}
.header_search_field .header_search_field__label_wrap {
    box-sizing: border-box;
    pointer-events: none;
    cursor: text;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.header_search_field .header_search_field__label_wrap::after {
    content: '';
    box-sizing: border-box;
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.header_search_field__input:focus ~ .header_search_field__label_wrap::after {
    opacity: 1;
    border-radius: 0;
}
.header_search_field__label_wrap {
    overflow: hidden;
    color: #000;
    border-radius: 0;
}
.header_search_field__label_wrap::after {
    border-bottom: 1px solid var(--secondaryColor);
    transform: translate3d(-100%, 0, 0);
    will-change: transform, opacity;
    transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
    border-radius: 0;
}
.header_search_field__input:focus ~ .header_search_field__label_wrap::after {
    transform: translate3d(0, 0, 0);
    transition-delay: 0;
    border-radius: 0;
}
.hidden {
    display: none;
}
.header_search_input_icon {
    position: absolute;
    top: 6px;
    left: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--light);
    opacity: 1;
    visibility: visible;
    display: inline;
    font-size: 13px;
}
.header_search_field__input {
    padding-bottom: 0;
}
.app_header__logo {
    padding: 0 1.5rem;
    height: 60px;
    display: flex;
    align-items: center;
    transition: width 0.2s;
}
.app_header__logo .logo_src img {
    width: 110px;
}
.btn-bell {
    font-size: 15px;
    animation-name: bell-ring;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes bell-ring {
    5%,
    15% {
        transform: rotate(25deg);
    }
    10%,
    20% {
        transform: rotate(-25deg);
    }
    25% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.header_icon {
    position: relative;
}
@media screen and (max-width: 500px) {
    .header_icon {
        width: 3.5vh !important;
        height: 3.5vh !important;
    }
    .header_icon i {
        font-size: 12px;
    }
}
.header_badge_counter {
    margin-top: -1rem;
    margin-right: -0.5rem;
    border-radius: 50%;
}
.header_profile_piccontainer img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0 10px #ccc;
}
@media screen and (max-width: 800px) {
    .header_profile_piccontainer img {
        width: 40px;
        height: 40px;
    }
}
/* Header Responsive */
.app_header_resp {
    padding: 0 20px 0 20px;
}
.app_header__logo_resp {
    display: flex;
    align-items: center;
}
.app_header__logo_resp .logo_src img {
    width: 90px;
}
@media screen and (max-width: 500px) {
    .app_header__logo_resp .logo_src img {
        width: 85px;
    }
}
/* Header Search List */
.header_search_list_container {
    z-index: 1000;
    background-color: #fff;
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    margin: auto;
    height: 200px;
    border-radius: 0 0 20px 20px;
}
.header_search_list_container_inner {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 180px;
}
.header_search_list_img img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 0 10px #ccc;
}
.header_searchlist_username {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    line-height: 15px;
}
.header_searchlist_type {
    font-size: 14px;
    line-height: 15px;
    color: var(--secondaryColor2);
}
.header_searchlist_icon i {
    font-size: 15px;
    color: var(--light);
    opacity: 0.7;
    transition: 0.3s;
}
.header_searchlist_card:hover .header_searchlist_icon i {
    opacity: 1;
}
/*********** Side Messages ***********/
.sidemsgs_cont {
    display: inline-block;
    position: fixed;
    z-index: 1000;
    bottom: 5%;
    right: 2%;
    position: fixed;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 10px #ccc;
    transition: 0.3s;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media screen and (max-width: 500px) {
    .sidemsgs_cont {
        right: 3%;
    }
}
.sidemsgs_cont:hover {
    color: #ffbc00;
}
.sidemsgs_cont .sidemsgs_badge {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background: red;
    color: white;
    min-width: 2.5vh;
    width: fit-content;
    height: 2.5vh;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.sidemsgs_cont .sidemsgs_badge:empty {
    display: none;
}
.header_chat_box_outer_container {
    background: #efefef;
    position: fixed;
    right: 30px;
    bottom: 20px;
    width: 450px;
    height: 80%;
    border-radius: 5px 5px 20px 20px;
    border: 2px solid #ccc;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 1000;
}
@media screen and (max-width: 450px) {
    .header_chat_box_outer_container {
        width: 90%;
        right: 18px;
    }
}
.close_chatoverlaybtn {
    width: 5vh;
    height: 5vh;
    background-color: white;
    color: #000;
    border-radius: 50%;
    box-shadow: 0 0 10px #ccc;
    float: right;
    margin-top: -4%;
    right: -2.5%;
    transition: 0.3s;
    z-index: 2000;
    position: absolute;
    cursor: pointer;
}
.close_chatoverlaybtn:hover {
    color: #ffbc00;
    transform: scale(1.1);
}
.chatbox_inner_container {
    width: 100%;
    background: #fff;
    border-radius: 5px 5px 20px 20px;
    overflow: hidden;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
}
.friendslist {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.friendslist::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.friend {
    position: relative;
}
.friend .friend_profimg_cont {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    border: 3px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.friend_profimg_cont img {
    max-width: 95%;
    max-height: 95%;
    border-radius: 50%;
}
.friend p {
    float: left;
    width: 220px;
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-transform: capitalize;
}
@media screen and (max-width: 800px) {
    .friend p {
        width: 350px;
    }
}
@media screen and (max-width: 500px) {
    .friend p {
        width: 280px;
    }
}
@media screen and (max-width: 400px) {
    .friend p {
        width: 200px;
    }
}
@media screen and (max-width: 350px) {
    .friend p {
        width: 100px;
    }
}
.friend p .chat_timestamp {
    font-size: 12px;
    font-weight: normal;
    color: var(--secondaryColor2);
}
.friend:hover .chatusername {
    transition: 0.3s;
    cursor: pointer;
    color: #ffbc00;
}
.friend .status {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 17px;
    width: 3.5vh;
    height: 3.5vh;
    border-radius: 50%;
    background-color: #ffbc00;
    color: white;
    font-size: 12px;
}
.chatsearchcont {
    width: 100%;
    color: #000;
    font-weight: 500;
}
.chatview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    position: relative;
    display: block;
}
.chatprofile {
    height: 50px;
    overflow: hidden;
    text-align: center;
    color: #000;
    width: 100%;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
    width: 145px;
    position: absolute;
}
.floatingImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 8%;
    border: 3px solid #fff;
    border-width: 100%;
}
.chatprofile p {
    font-weight: 600;
    font-size: 15px;
    margin: 118px 0 -1px;
    opacity: 0;
    margin-left: 20%;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
.chatprofile p.animate {
    margin-top: 13px;
    margin-left: 18%;
    opacity: 1;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
@media screen and (max-width: 450px) {
    .chatprofile p.animate {
        margin-left: 21%;
    }
}
/* @media screen and (max-width: 380px) {
    .chatprofile p.animate { */
/* margin-top: 21px;
        margin-left: 26%; */
/* }
} */
@media screen and (max-width: 325px) {
    .chatprofile p.animate {
        margin-left: 26%;
    }
    .floatingImg {
        left: 10%;
    }
}
.chatprofile .chatprofileIcon {
    font-size: 20px;
    position: absolute;
    top: 15px;
    left: 15px;
    transition: 0.3s;
    cursor: pointer;
}
.chatprofile .chatprofileIcon:hover {
    color: var(--secondaryColor);
}
.chatprofile span {
    font-size: 15px;
    opacity: 0;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
.chat_area {
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.chat_msg_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ccc;
}
.chat_msg_profile {
    flex-shrink: 0;
    margin-top: auto;
    position: relative;
}
.chat_msg_date {
    position: absolute;
    left: calc(100% + 12px);
    bottom: 0;
    font-size: 12px;
    font-weight: 600;
    color: var(--secondaryColor2);
    white-space: nowrap;
    margin-bottom: -20px;
}
.chat_msg {
    display: flex;
    padding: 0 20px 30px;
}
.chat_msg_content {
    margin-left: 12px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.chat_msg_text {
    background: #f0f4f7;
    padding: 7px 15px;
    border-radius: 5px 5px 5px 0px;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    word-break: break-word;
}
.chat_msg_text + .chat_msg_text {
    margin-top: 10px;
}
.owner {
    flex-direction: row-reverse;
}
.owner .chat_msg_content {
    margin-left: 0;
    margin-right: 12px;
    align-items: flex-end;
}
.owner .chat_msg_text {
    background-color: rgba(0, 0, 0, 0.1);
    color: #000;
    /*border-radius: 20px 20px 0 20px;*/
    border-radius: 5px 5px 0px 5px;
}
.owner .chat_msg_date {
    left: auto;
    right: calc(100% + 12px);
}
.chat_msg_text img {
    max-width: 300px;
    width: 100%;
}
.chat_area_main.animate {
    opacity: 1;
    margin-top: 0;
}
.send_message_container {
    height: 60px;
    border-top: 1px solid #e7ebee;
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 100%;
    background: #fff;
}
.send_message_container input {
    background: #fff;
    border: none;
    padding: 0;
    font-size: 14px;
    font-weight: 400px;
    color: #000;
    width: 94%;
    height: 100%;
    padding: 1%;
}
@media screen and (max-width: 380px) {
    .send_message_container input {
        width: 85%;
    }
}
.send_message_container input:focus {
    outline: 0;
}
.send-msg-btn {
    position: absolute;
    right: 3%;
    top: 35%;
    margin: auto;
    transition: 0.3s;
    cursor: pointer;
}
.send-msg-btn:hover {
    color: var(--secondaryColor);
}
#close {
    position: absolute;
    top: 8px;
    opacity: 0.8;
    left: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
#close:hover {
    opacity: 1;
}
.chatcloseicon {
    background: #000;
    position: absolute;
    width: 0px;
    top: 9px;
    right: 15px;
    font-size: 23px;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}
.chatcloseicon i {
    transition: 0.3s;
}
.chatcloseicon:hover i {
    color: var(--secondaryColor);
}
.chatview,
.send_message_container {
    overflow: hidden;
    border-radius: 6px;
}
.chatview .chat_messageswrapper {
    overflow: auto;
    height: 85%;
    margin-top: 50px;
}
.chatview .chat_messageswrapper::-webkit-scrollbar {
    width: 5px;
}
.chatview .chat_messageswrapper::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
}
.chatview .chat_messageswrapper::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 5px;
}
.chatview .chat_messageswrapper::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
}
.app_header_responsive {
    display: none;
}
@media (max-width: 1025px) {
    .app_header {
        display: none;
    }
    .app_header_responsive {
        display: flex;
    }
    .app_header {
        justify-content: space-between;
    }
    .app_header .app_header__content-resp {
        visibility: hidden;
        opacity: 0;
        box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
        position: absolute;
        left: 5%;
        width: 90%;
        top: 0;
        transition: all 0.2s;
        background: #fff;
        border-radius: 50px;
        padding: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex: 1;
    }
    .app_header .app_header__content-resp.header-mobile-open {
        visibility: visible;
        opacity: 1;
        top: 60px;
        z-index: 1000;
        height: 50px;
        padding: 0 10px;
    }
}

/* Notification Dropdown */
.notification_dropdown_username {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    line-height: 14px;
}
.notification_dropdown_image {
    width: 5vh;
    height: 5vh;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 10px #ccc;
    margin: auto;
}
.notification_dropdown_content {
    font-size: 12px;
}
.notification_dropdown .dropdown-menu {
    padding: 10px 0;
}
.notification_dropdown .dropdown-menu::after {
    content: '';
    position: absolute;
    top: 0;
    right: 20px;
    margin-top: -15px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}
.notification_dropdown .dropdown-item {
    padding: 5px 0 5px 5px;
}
.notidfication_profile_image_container img {
    width: 40px !important;
    height: 40px !important;
}
/* Hamburger */
.hamburger {
    padding: 0px 0px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger:hover .hamburger_inner {
    background-color: #ffbc00;
}
.hamburger:hover .hamburger_inner::before {
    background-color: #ffbc00;
}
.hamburger:hover .hamburger_inner::after {
    background-color: #ffbc00;
}
.hamburger.active:hover {
    opacity: 0.7;
}
.hamburger.active .hamburger_inner,
.hamburger.active .hamburger_inner::before,
.hamburger.active .hamburger_inner::after {
    background-color: #000;
}
.hamburger_box {
    width: 24px;
    height: 14px;
    display: inline-block;
    position: relative;
}
.hamburger_inner {
    display: block;
    top: 50%;
}
.hamburger_inner,
.hamburger_inner::before,
.hamburger_inner::after {
    content: '';
    display: block;
    height: 1.3px;
    width: 26px;
    height: 1.3px;
    background-color: #000;
    border-radius: 10px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger_inner::before {
    top: -6px;
    height: 1.3px;
}
.hamburger_inner::after {
    bottom: -6px;
    height: 2px;
}
.hamburger_elastic .hamburger_inner {
    top: 5px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger_elastic .hamburger_inner::before {
    top: 9px;
    transition: opacity 0.125s 0.275s ease;
}
.hamburger_elastic .hamburger_inner::after {
    top: 18px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger_elastic.isactive .hamburger_inner {
    transform: translate3d(0, 6px, 0) rotate(135deg);
    transition-delay: 0.075s;
}
.hamburger_elastic.isactive .hamburger_inner::before {
    height: 4px;
    transition-delay: 0s;
    opacity: 0;
}
.hamburger_elastic.isactive .hamburger_inner::after {
    transform: translate3d(0, -19px, 0) rotate(-270deg);
    transition-delay: 0.075s;
}
.active_animation {
    opacity: 1;
    display: block;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0s;
}
.inactive_animation {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: none;
}

.header_icon_msgs {
    width: 4vh !important;
    height: 4vh !important;
}
