input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
    background-color: orange;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
button {
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover {
    cursor: pointer;
}
input {
    outline: none;
    border: none;
    color: #000 !important;
}
textarea {
    outline: none;
    border: none;
    resize: none;
}
textarea:focus,
input:focus {
    border-color: transparent !important;
    background-color: transparent;
}
input::-webkit-input-placeholder {
    color: #555555;
    background-color: transparent;
}
input:-moz-placeholder {
    color: #555555;
    background-color: transparent;
}
input:-ms-input-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea::-webkit-input-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea:-moz-placeholder {
    color: #555555;
    background-color: transparent;
}
textarea:-ms-input-placeholder {
    color: #555555;
    background-color: transparent;
}

.field {
    position: relative;
    font-size: 1rem;
    border-radius: 0;
    margin-bottom: 5%;
    border-radius: 0;
}
.field__input {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem 0.3rem 1rem;
    border-bottom: 1px solid #ccc;
    color: #000;
    border-radius: 0;
    background-color: transparent;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 500;
}
/* Only for Safari  */

.field-website .field__input {
    color: white !important;
    padding: 1.25rem 1rem 0.2rem 1rem;
}
@media screen and (max-width: 450px) {
    .field__input {
        border-radius: 0;
    }
}
.field__input:focus {
    outline: none;
    background-color: transparent;
    border-radius: 0;
    color: #000;
}
.field__input::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.2s ease-out;
}
.field__input::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    border-radius: 0;
}
.field__label_wrap {
    box-sizing: border-box;
    pointer-events: none;
    cursor: text;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.field__label_wrap::after {
    content: '';
    box-sizing: border-box;
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0;
}
.field__input:focus ~ .field__label_wrap::after {
    opacity: 1;
    border-radius: 0;
}
.field__label {
    position: absolute;
    bottom: 10px;
    line-height: 1;
    font-size: 16px;
    transition: top 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15), opacity 0.2s ease-out, font-size 0.2s ease-out;
    will-change: bottom, opacity, font-size;
    color: var(--secondaryColor2);
    border-radius: 0;
}
.field-website .field__label {
    color: white;
    font-size: 15px;
}
.field__input:focus ~ .field__label_wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label_wrap .field__label {
    font-size: 0.75rem;
    top: 0.25rem;
    color: var(--secondaryColor2);
    border-radius: 0;
}
.field-website .field__input:focus ~ .field__label_wrap .field__label,
.field-website .field__input:not(:placeholder-shown) ~ .field__label_wrap .field__label {
    font-size: 0.75rem;
    top: 0.25rem;
    color: #ffbc00;
    border-radius: 0;
}
.field__label_select {
    font-size: 0.75rem !important;
    top: 0.25rem !important;
    color: #ffbc00 !important;
    border-radius: 0 !important;
}

.field_v2 .field__label_wrap {
    overflow: hidden;
    color: #000;
    border-radius: 0;
}
.field_v2 .field__label_wrap::after {
    border-bottom: 1px solid var(--secondaryColor);
    transform: translate3d(-100%, 0, 0);
    will-change: transform, opacity;
    transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
    border-radius: 0;
}
.field_v2 .field__input:focus ~ .field__label_wrap::after {
    transform: translate3d(0, 0, 0);
    transition-delay: 0;
    border-radius: 0;
}
.hidden {
    display: none;
}
.check_label {
    cursor: pointer;
    display: inline-block;
    color: #000;
    font-size: 15px;
    line-height: 22px;
}
.check_row_website .check_label {
    color: white !important;
    line-height: 22px;
}
[type='checkbox']:checked + .check_label {
    color: #ffbc00 !important;
}
[type='radio']:checked + .check_label {
    color: #ffbc00 !important;
}
.check_box {
    display: inline-block;
    vertical-align: middle;
    left: 0;
    top: 0;
    width: 1.5em;
    height: 1.5em;
    transition: all 0.25s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transform-style: preserve-3d;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
[type='checkbox']:checked + .check_label .check_box {
    transform: rotateX(90deg);
    transition: all 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
[type='radio']:checked + .check_label .check_box {
    transform: rotateX(90deg);
    transition: all 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.check_off,
.check_on {
    display: inline-block;
    font-size: 13px;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.check_off {
    color: #ccc;
    border-radius: 5px;
    transform: translateZ(0.5em);
}
.check_on {
    color: var(--secondaryColor);
    transform: rotateX(-90deg) translateZ(0.5em);
}
.check_row:last-child {
    border: none;
}
.check_on {
    /* text-shadow: 0 0 20px white; */
}

.focus_input100 i {
    color: #b8c3d5;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 35px);
    bottom: 0;
    left: 0;
    padding-top: 6px;
    display: none !important;
    font-size: 13px;
}

.field__input:focus ~ .focus_input100 i,
.field__input:not(:placeholder-shown) ~ .focus_input100 i {
    display: inline !important;
}
.input-icon {
    color: white !important;
    font-size: 15px !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    height: calc(100% - 20px) !important;
    bottom: 0 !important;
    left: 0 !important;
    padding-left: 10px !important;
    padding-top: 4px !important;
    display: none !important;
    font-size: 13px !important;
}
.field-website .input-icon{
    height: calc(100% - 32px) !important;
}
.input-icon.calendarwebsite {
    bottom: -5px !important;
    height: calc(100% - 20px) !important;
}

.field__input:focus ~ .focus_input100 .input-icon,
.field__input:not(:placeholder-shown) ~ .focus_input100 .input-icon {
    display: inline !important;
}
.field-website-apply .field__input {
    padding-left: 2rem;
}

.field__input:disabled {
    cursor: not-allowed;
}
html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}