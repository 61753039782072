@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Scope+One&display=swap');

.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
  
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/*** Main Pre-loader ***/
.loading-page-container{
  width: 100%;
  height: 100vh;
  background: #161616;
  
}
.loading-page {
  /* background: rgba(251,250,249,1); */
  background: url(https://spotearned.com/storage/spotwebsite/hero.gif) repeat  50% 50%;
  height: 100%;
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  transition: opacity 0.5s;
 
}

.loading-page .counter h1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;
  font-size: 260px;
  font-weight: bolder;
  margin-top: -10px;
  color:white;
}
.preloaderfadeOut{
  opacity:0;
  width:0;
  height:0;
  /* background: #857f7f; */
  z-index: -99999999999999999999999999999990;
  /* position:relative; */

  visibility:hidden;
  /* transition:  opacity 0.5s; */
  /* display:none; */
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;

}
html{
  margin:0;
  padding:0;
  height:100%;
}
body{
  margin:0;
  padding:0;
  height:100%;
  font-family: 'Montserrat', sans-serif;
}
